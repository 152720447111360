<template lang="pug">
div
  van-row(type="flex" justify="center")
    van-col
      h3 {{$store.state.companyInfo.com_name}}
  van-form
    van-field(v-model='mobile', clearable  name='mobile', label='手机号', placeholder='手机号', :rules="[{ required: true, message: '请填写手机号' }]")
    van-field(v-model='code', clearable name='code', label='验证码', placeholder='验证码', :rules="[{ required: true, message: '请填写验证码' }]")
      template(#button)
        van-button(size='small', type='default' @click="sendCode" :disabled="verifyState") {{verify}}
    van-row.row
      van-button.col(type='info' native-type='submit' block  round @click='onSubmit' :disabled='commitState') 登录
      van-button.col(type='default' block  round :to="{name: 'login'}") 账号密码登录
</template>

<script>
import {LoginCallBack} from '@/lib/helpers/Token'

let startTime = 60

export default {
  data() {
    return {
      commitState: false,
      mobile: '',
      code: '',
      verifyState: false,
      verify: '获取动态密码'
    }
  },
  methods: {
    async onSubmit(values) {
      if (!this.mobile) {
        this.$toast('手机号不能为空')
      } else if (!this.code) {
        this.$toast('请输入验证码')
      } else if(this.mobile.length != 11) {
        this.$toast('请输入正确的手机号')
      } 
      else {
        try {
          this.commitState = true
          let params = {
              mobile: this.mobile,
              code: this.code,
              com_id: this.$store.state.companyInfo.id
          }
          let res = await this.$api.QuickLogin(params)
          await LoginCallBack(this, res.data)
          this.$toast("登录成功")
        } catch (e) {
          this.$toast(this.$error(e))
        }
        this.commitState = false
      }
    },
    countdowntime() {
      let time = setTimeout(this.countdowntime, 1000)
      if (startTime === 0) {
        clearTimeout(time)
        this.verify = '获取动态密码'
        startTime = 60
        this.verifyState = false
        return false
      } else {
        startTime--
      }
      this.verify = startTime + 's后重新获取'
    },
    async sendCode() {
      if (!this.verifyState) {
        try {
          let params = {
            type: 'web',
            mobile: this.mobile
          }
          await this.$api.RequestSmsCode(params)
          this.verifyState = true
          this.countdowntime()
          this.$toast('验证码发送成功!')
        } catch (e) {
          this.$toast(this.$error(e))
        }
      }
    }
  },
  async mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0px 10px;
}
.col {
  margin: 10px 0px;
}
</style>